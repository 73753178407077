import MediaService from '@comrock/vub-wls-media-service';
import MediaProviderCCSearchInMemory from '@comrock/vub-wls-media-provider-cc-search-in-memory';
import config from './config';

const mediaProviderCCSearchInMemory = new MediaProviderCCSearchInMemory({
    serviceURL: config.CC_SEARCH_API_URI,
    apiKey: config.CC_SEARCH_API_KEY,
    apiVersion: config.CC_SEARCH_API_VERSION,
    providerId: config.CC_SEARCH_API_PROVIDER_ID,
    provider: config.CC_SEARCH_API_PROVIDER,
});

export const mediaService = new MediaService(mediaProviderCCSearchInMemory);
