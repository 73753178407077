import * as classifications from './contentRowClassifications';

export const MediaCarouselStandard = {
    title: 'Default Title',
    slideTemplate: classifications.CONTENT_ROW_SLIDE_TEMPLATE_MEDIA_CAROUSEL_SLIDE_PORTRAIT,
    featuredSlideTemplate: classifications.CONTENT_ROW_SLIDE_TEMPLATE_MEDIA_CAROUSEL_SLIDE_PORTRAIT,
    displaySeeMoreSlide: true,
    mediaLimit: 24,
    mediaLimitForSubtitle: 6,
};

export const MediaHeroBannerStandard = {
    sliderSpeed: 5000,
};

export const MediaHeroBannerPreview = {
    playbackDelay: 3000,
};

export const MediaStripBannerStandard = {
    backgroundColor: '#ffffff',
    bannerImageURI: '/',
    bannerLogoURI: '/',
};
