export const CONTENT_ROW_TYPE_MEDIA_GENRE = 'MediaGenre';
export const CONTENT_ROW_TYPE_MEDIA_COLLECTION = 'MediaCollection';
export const CONTENT_ROW_TYPE_MEDIA_SET = 'MediaSet';

export const CONTENT_ROW_TEMPLATE_MEDIA_CAROUSEL_STANDARD = 'MediaCarouselStandard';
export const CONTENT_ROW_TEMPLATE_MEDIA_HERO_BANNER_STANDARD = 'MediaHeroBannerStandard';
export const CONTENT_ROW_TEMPLATE_MEDIA_HERO_BANNER_PREVIEW = 'MediaHeroBannerPreview';
export const CONTENT_ROW_TEMPLATE_MEDIA_STRIP_BANNER_STANDARD = 'MediaStripBannerStandard';

export const CONTENT_ROW_SLIDE_TEMPLATE_MEDIA_CAROUSEL_SLIDE_PORTRAIT = 'MediaCarouselSlidePortrait';
export const CONTENT_ROW_SLIDE_TEMPLATE_MEDIA_CAROUSEL_SLIDE_LANDSCAPE = 'MediaCarouselSlideLandscape';
export const CONTENT_ROW_SLIDE_TEMPLATE_MEDIA_CAROUSEL_SLIDE_DOUBLE = 'MediaCarouselSlideDouble';

export const contentRowTypes = [
    {
        key: CONTENT_ROW_TYPE_MEDIA_GENRE,
        label: 'Media Genre',
    },
    {
        key: CONTENT_ROW_TYPE_MEDIA_COLLECTION,
        label: 'Media Collection',
    },
    {
        key: CONTENT_ROW_TYPE_MEDIA_SET,
        label: 'Media Set',
    },
];

export const contentRowTemplates = [
    {
        key: null,
        label: 'None',
    },
    {
        key: CONTENT_ROW_TEMPLATE_MEDIA_CAROUSEL_STANDARD,
        label: 'Media Carousel - Standard',
    },
    {
        key: CONTENT_ROW_TEMPLATE_MEDIA_HERO_BANNER_STANDARD,
        label: 'Media Hero Banner - Standard',
    },
    {
        key: CONTENT_ROW_TEMPLATE_MEDIA_HERO_BANNER_PREVIEW,
        label: 'Media Hero Banner - Preview',
    },
    {
        key: CONTENT_ROW_TEMPLATE_MEDIA_STRIP_BANNER_STANDARD,
        label: 'Media Strip Banner - Standard',
    },
];

export const contentRowSlideTemplates = [
    {
        key: null,
        label: 'None',
    },
    {
        key: CONTENT_ROW_SLIDE_TEMPLATE_MEDIA_CAROUSEL_SLIDE_PORTRAIT,
        label: 'Media Carousel Slide - Portrait',
    },
    {
        key: CONTENT_ROW_SLIDE_TEMPLATE_MEDIA_CAROUSEL_SLIDE_LANDSCAPE,
        label: 'Media Carousel Slide - Landscape',
    },
    {
        key: CONTENT_ROW_SLIDE_TEMPLATE_MEDIA_CAROUSEL_SLIDE_DOUBLE,
        label: 'Media Carousel Slide - Double',
    },
];
