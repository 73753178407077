import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import {
    TextField,
} from '@material-ui/core';
import {Labeled} from 'react-admin';

import actions from '../actions';

class MediaSetInput extends Component {
    static propTypes = {
        collections: PropTypes.array,
        input: PropTypes.object,
        value: PropTypes.string,
        onChange: PropTypes.func,
        name: PropTypes.string,
    };

    static defaultProps = {
        collections: [],
        input: null,
        value: '',
        onChange: null,
        name: '',
    };

    render() {
        const {
            collections,
            input,
            value,
            onChange,
            name,
        } = this.props;

        return (input || value || onChange) && collections ? (
            <Labeled label="Media set">
                <TextField
                    name={name}
                    value={input ? input.value : value}
                    onChange={input
                        ? value => input.onChange(value)
                        : event => onChange(event)}
                    fullWidth={true}
                />
            </Labeled>
        ) : null;
    }
}

const mapStateToProps = state => ({
    collections: state.contentRowProperties.collectionHash,
});

const mapDispatchToProps = dispatch => ({
    getCollections: dispatch(actions.getCollections()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaSetInput);
